import React from "react";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import { Button, Container, Dropdown, Tab, Table, Tabs } from "react-bootstrap";
import LeftBarSports from "../../../components/LeftBarSports";

import BackIcon from "../../../../assets/svg/BackIcon";
import { IoDiamond } from "react-icons/io5";
import { FiUsers } from "react-icons/fi";
import { AiFillCopy } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import Footer from "../../../../containers/Footer";
import { useSWRFetcher } from "../../../../hooks/useSWRHooks";
import { API_URLS } from "../../../../config/apiUrls";
// import { useClipboard } from "@mantine/hooks";
import CopyToClipboard from "react-copy-to-clipboard";
import { APP_CONST } from "../../../../config/const";
import { Toaster } from "../../../../utils/toaster";

const Referral = () => {
  // const clipboard = useClipboard({ timeout: 500 });

  const navigate = useNavigate();
  const { data: referralGenerate } = useSWRFetcher({
    url: API_URLS.REFERRAL.GENERATE,
    isExternalFetch: true,
  });

  const { data: refStats } = useSWRFetcher({
    url: API_URLS.REFERRAL.STATS,
    isExternalFetch: true,
    swrConfig: {
      refreshInterval: 20_000,
    },
  });
  const { data: refSource } = useSWRFetcher({
    url: API_URLS.REFERRAL.SOURCES,
    isExternalFetch: true,
    swrConfig: {
      refreshInterval: 20_000,
    },
  });
  return (
    <div>
      <HeaderAfterLogin />
      <main className="main mt-4">
        {/* <div className="d-xl-none mt--14">
          <LeftBarSports />
        </div> */}
        <div className="transfer_info_page ">
          <Container>
            <div className="PageSection">
              <div className="PageRightBar">
                <div className=" HeadingSection RightBarHead heading d-flex justify-content-between align-items-center">
                  <h4>Referral</h4>
                  <div className="back-link" onClick={() => navigate(-1)}>
                    Back <BackIcon />
                  </div>
                </div>

                <div className=" ">
                  <div className="referral_card_main mt-4">
                    <div className="referrals_cards p-4">
                      <h5>Total Reffered Users</h5>
                      <div className="referr_user">
                        <FiUsers className="referral_card_icon" />{" "}
                        <p>{refStats?.users}</p>
                      </div>
                    </div>
                    <div className="referrals_cards p-4">
                      <h5>Total Bonus Earned</h5>
                      <div className="referr_user">
                        <IoDiamond className="referral_card_icon" />{" "}
                        <p>{refStats?.amount}</p>
                      </div>
                    </div>
                    <div className="referrals_cards p-4">
                      <h5>Total Link Visits</h5>
                      <div className="referr_user">
                        <IoDiamond className="referral_card_icon" />{" "}
                        <p>{refStats?.visits}</p>
                      </div>
                    </div>
                  </div>
                  <div className="referral_link mt-4">
                    <h5>Referral Code </h5>{" "}
                    <div>{referralGenerate?.referralCode}</div>
                    <Button
                      className=""
                      // onClick={() =>
                      //   clipboard.copy(referralGenerate?.referralLink)
                      // }
                    >
                      <CopyToClipboard
                        text={referralGenerate?.referralLink}
                        onCopy={() => {
                          Toaster(APP_CONST.TOAST_TYPE.SUCCESS, "Text Copied");
                        }}
                      >
                        <AiFillCopy />
                      </CopyToClipboard>
                      {/* {clipboard.copied ? "Copied" : "Copy Link"} */}
                    </Button>
                  </div>

                  <div className="referr_tabs mt-5">
                    <Tabs
                      defaultActiveKey="Source"
                      id="uncontrolled-tab-example"
                      className="mb-3"
                    >
                      {/* <Tab eventKey="Referred" title="Referred Users">
                    <div className="table-responsive ">
                      <Table className="table referr_table ">
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Bonus</th>
                            <th>Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Tommy Lee</td>
                            <td>{refStats?.amount}</td>
                            <td>
                              {startdate} - {endDate}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </Tab> */}
                      <Tab eventKey="Source" title="Sources">
                        <div className=" ">
                          <Table className="table referr_table ">
                            <thead>
                              <tr>
                                <th>Domain</th>
                                <th>Visit Count</th>
                              </tr>
                            </thead>
                            <tbody>
                              {refSource &&
                                Array.isArray(refSource?.urls) &&
                                refSource?.urls.map((item) => (
                                  <tr key={item?.domain}>
                                    <td>{item?.domain}</td>
                                    <td>
                                      {" "}
                                      <Dropdown>
                                        <Dropdown.Toggle
                                          variant="success"
                                          id="dropdown-basic"
                                        >
                                          {item?.visitCount}
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                          {Array.isArray(item.urls) &&
                                            item.urls.map((urlpaths) => (
                                              <Dropdown.Item
                                                key={
                                                  item?.domain + urlpaths.url
                                                }
                                                className="border-bottom"
                                              >
                                                <div>{urlpaths.url}</div>{" "}
                                                <div>{urlpaths.count}</div>
                                                <Dropdown.Divider />
                                              </Dropdown.Item>
                                            ))}
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </Table>
                        </div>
                      </Tab>
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </main>

      <Footer />
    </div>
  );
};

export default Referral;

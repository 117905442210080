import DeviceDetector from "device-detector-js";
import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { Navigate } from "react-router-dom";
import { APP_CONST } from "../../../../config/const";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import { socket } from "../../../../utils/socketUtil";
import { Toaster } from "../../../../utils/toaster";
import ExchangeFundsForm from "../../../components/ExchangeFundsForm";
import { axiosService } from "../../../../utils/axiosService";
import LoadingImg from "../../../../assets/images/loader.gif";

const QtechLobbyPage = () => {
  const [casinoLobbyUrl, setCasinoLobbyUrl] = useState();

  useEffect(() => {
    console.log("useEffect");
    try {
      if (localStorage.getItem("token") !== null) {
        const result = axiosService.post(
          "https://qtech.sportsinfo247.com/launch-games",
          {
            token: localStorage.getItem("token"),
            mobile: false,
            gameId: "",
          }
        );
        result
          .then((res) => {
            console.log(res);
            if (!res?.data?.error) {
              return setCasinoLobbyUrl(res?.data?.lobbyUrl);
            }
          })
          .catch((err) => {
            console.log(err);
            return;
          });
      }
    } catch (err) {
      console.log(err);
      return;
    }
  }, []);

  return (
    <>
      <HeaderAfterLogin />

      <div className="live_casino_page live">
        {!Boolean(casinoLobbyUrl) ? (
          <div className="custom_placeholder_parent">
            {/* <div className="custom_placeholder_child">
            <Spinner animation="grow" variant="primary" size="sm" />
            <Spinner animation="grow" variant="primary" size="sm" />
            <Spinner animation="grow" variant="primary" size="sm" />
          </div> */}
            <div className="loading_img">
              <img src={LoadingImg} alt="loading" width={80} />
            </div>
          </div>
        ) : casinoLobbyUrl === "notAllowed" ? (
          <Navigate to="/home" />
        ) : casinoLobbyUrl === "notFound" ? (
          <Navigate to="/home" />
        ) : (
          <div className="exchange_iframe">
            <div>
              <iframe
                title="Exchange"
                src={casinoLobbyUrl}
                frameBorder="0"
                style={{
                  width: "100%",
                  height: "100vh",
                  maxHeight: "calc(100vh - 80px)",
                  // aspectRatio: "16/9",
                }}
                // scrolling="no"
                allowFullScreen={true}
              >
                Your browser doesn't support iFrames.
              </iframe>
            </div>
          </div>
        )}
        {/*  Iframe End */}
      </div>
    </>
  );
};

export default QtechLobbyPage;
